import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"
import { useMediaPredicate } from "react-media-hook"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../components/cover-main/cover-main"
import { Card } from "../../components/card/card"
import { Section } from "../../components/grid/grid"
import { TabLink } from "../../components/tab-header/tab-header"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_STATIC_DATA = graphql`
  query {
    imgCoverMain: file(relativePath: { eq: "news-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allNewsJson {
      edges {
        node {
          newsItems {
            id
            category
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            url
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    wordPress {
      categories {
        nodes {
          name
          slug
          posts(first: 1000) {
            nodes {
              id
              slug
              title
              date
              featuredImage {
                sourceUrl
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              translation(language: ID) {
                id
                slug
                title
                date
                featuredImage {
                  sourceUrl
                }
                categories {
                  nodes {
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      }
      page(id: "news-2", idType: URI) {
        title
        slug
        featuredImage {
          mediaItemUrl
        }
        blocks {
          name
          ... on WordPress_CoreCoverBlock {
            attributes {
              url
              className
            }
          }
        }
        translation(language: ID) {
          title
          slug
          blocks {
            name
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          opengraphTitle
          opengraphDescription
        }
      }
      allNews: posts(first: 1000, where: { language: EN }) {
        nodes {
          id
          slug
          title
          date
          featuredImage {
            sourceUrl
          }
          categories {
            nodes {
              name
              slug
            }
          }
          translation(language: ID) {
            id
            slug
            title
            date
            featuredImage {
              sourceUrl
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`

// const NewsItems = ({ data, defaultImage, label, state }) => {
//   return data ? (
//     data.posts.nodes.map((item, i) => {
//       if (i > state * 10 - 10 && i < state * 10) {
//         return (
//           <Card
//             id={item.id}
//             key={item.id}
//             variant="basic"
//             colLeft="col-md-5"
//             colRight="col-md-7"
//             label={label || "Blog"}
//             title={item?.title}
//             // excerpt={item.excerpt}
//             text={moment(item.date).format("DD/MM/YYYY")}
//             img={
//               item.featuredImage ? item.featuredImage.sourceUrl : defaultImage
//             }
//             imgHeight="h-ratio-3-2"
//             link={`/news/${item.slug}`}
//             className="card-post"
//           />
//         )
//       }
//     })
//   ) : (
//     <div className="py-main"></div>
//   )
// }

const News = ({ location }) => {
  const [allNewsOffset, setAllNewsOffset] = useState(1)

  const staticData = useStaticQuery(GET_STATIC_DATA)

  const allNewsStatic = staticData.wordPress.allNews.nodes

  const wordPress = staticData?.wordPress?.page
  const seo = wordPress?.seo

  const cover =
    wordPress && wordPress?.blocks?.find(item => item.name === "core/cover")

  let coverItems = []

  const resizeScreen = useMediaPredicate("(max-width: 992px)")

  const featuredImage = wordPress?.featuredImage?.mediaItemUrl

  const Pagination = ({ hasPreviousPage, hasNextPage, prevPage, nextPage }) => {
    return (
      <div className="d-flex justify-content-end">
        <div className="paginate">
          <button
            className="btn btn-primary pagination-button"
            disabled={hasPreviousPage}
            onClick={prevPage}
          >
            <i className="fas fa-chevron-left" />
          </button>
          <button
            className="btn btn-primary pagination-button"
            disabled={hasNextPage}
            onClick={nextPage}
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    )
  }

  const allNewsNextPage = () => {
    if (allNewsStatic[allNewsOffset * 10] !== undefined) {
      setAllNewsOffset(allNewsOffset + 1)
    }
  }
  const allNewsPrevPage = () => {
    if (allNewsOffset >= 1) {
      setAllNewsOffset(allNewsOffset - 1)
    }
  }

  // eslint-disable-next-line
  wordPress && wordPress?.blocks?.map(item => {
    if(item.name === "core/cover"){
      coverItems.push({url: item?.attributes?.url, className: item?.attributes?.className})
    }
  })

  useEffect(() => {
    // eslint-disable-next-line
    wordPress && wordPress?.blocks?.map(item => {
      if(item.name === "core/cover"){
        coverItems.push({url: item?.attributes?.url, className: item?.attributes?.className})
      }
    })
  }, [])

  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={featuredImage}
        twitterImg={featuredImage}
        img={featuredImage}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <CoverMain
        h1={wordPress?.title}
        img={ resizeScreen ? coverItems[1]?.url : coverItems[0]?.url }
        className={`cover-md cover-detail ${resizeScreen ? coverItems[1]?.className : coverItems[0]?.className}`}
      >
        <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
      </CoverMain>

      <Section className="py-0">
        <TabLink
          itemCenter
          mobileCol={5}
          textCenter
          path="/news"
          data={[
            { name: "All", target: "/news" },
            { name: "Blog", target: "/news/blog" },
            { name: "Press Release", target: "/news/press-release" },
            {
              name: "Event & Program",
              target: "/news/events",
            },
            { name: "Sustainability News", target: "/news/sustainability" },
          ]}
        />
        <div className="tab-content">
          <section
            id="newsAll"
            className="tab-pane fade show section-news active"
            role="tabpanel"
          >
            <div className="container py-main px-sm-down-0">
              {allNewsStatic.map((item, i) => {
                if (i > allNewsOffset * 10 - 10 && i < allNewsOffset * 10 || i === 0) {
                  return (
                    <Card
                      id={item.id}
                      key={`${item.id}-${i}`}
                      variant="basic"
                      colLeft="col-md-5"
                      colRight="col-md-7"
                      label={item.categories.nodes[0].name
                        .split("amp;")
                        .join("")}
                      title={item?.title?.split("#038;")?.join("")}
                      // excerpt={item.excerpt}
                      text={moment(item.date).format("DD/MM/YYYY")}
                      img={
                        item.featuredImage
                          ? item.featuredImage.sourceUrl
                          : staticData.imgCoverMain.childImageSharp.fluid
                      }
                      imgHeight="h-ratio-3-2"
                      link={`/news/${item.slug}`}
                      className="card-post pb-3"
                    />
                  )
                }
              })}
              <div className="mb-5">
                <Pagination
                  hasPreviousPage={allNewsOffset === 1}
                  hasNextPage={
                    allNewsStatic[allNewsOffset * 10 + 1] === undefined
                  }
                  prevPage={allNewsPrevPage}
                  nextPage={allNewsNextPage}
                />
              </div>
            </div>
          </section>
        </div>
      </Section>
    </Layout>
  )
}

export default News
